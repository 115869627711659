export class CustomRateSchedule {
  public jan: (number | undefined)[] = Array(24).fill(undefined);
  public feb: (number | undefined)[] = Array(24).fill(undefined);
  public mar: (number | undefined)[] = Array(24).fill(undefined);
  public apr: (number | undefined)[] = Array(24).fill(undefined);
  public may: (number | undefined)[] = Array(24).fill(undefined);
  public jun: (number | undefined)[] = Array(24).fill(undefined);
  public jul: (number | undefined)[] = Array(24).fill(undefined);
  public aug: (number | undefined)[] = Array(24).fill(undefined);
  public sep: (number | undefined)[] = Array(24).fill(undefined);
  public oct: (number | undefined)[] = Array(24).fill(undefined);
  public nov: (number | undefined)[] = Array(24).fill(undefined);
  public dec: (number | undefined)[] = Array(24).fill(undefined);

  constructor(schedule = {} as Partial<CustomRateSchedule>) {
    Object.assign(this, schedule);
  }
}
