import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HasSessionGuard } from '@app/core/guards/has-session/has-session.guard';
import { NoSessionGuard } from '@app/core/guards/no-session/no-session.guard';

const routes: Routes = [
  {
    path: 'signin',
    canLoad: [NoSessionGuard],
    loadChildren: () => import('./modules/signin/signin.module').then(m => m.SigninModule)
  },
  {
    path: 'signup',
    canLoad: [NoSessionGuard],
    loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: 'account',
    canLoad: [HasSessionGuard],
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'projects',
    // temporarily disable the HasSessionGuard as this appears to cause an issue
    // when navigating to the projects list after saving a project's changes
    // canLoad: [HasSessionGuard],
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'project/:projectId',
    canLoad: [HasSessionGuard],
    loadChildren: () => import('./modules/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: '**',
    redirectTo: 'signin',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always'
    }
  )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
