import { Injectable } from '@angular/core';
import { getProperty, setProperty, hasProperty, deleteProperty } from 'dot-prop';
import clone from 'just-clone';
import { Apollo, gql } from 'apollo-angular';
import { makeVar, ReactiveVar, DocumentNode } from '@apollo/client/core';
import { Observable, Subject } from 'rxjs';

import {
  Project,
  SurveyAnswerInput,
  SurveyAnswer,
  SurveyQuestion
} from '@app/core/services/graphql/graphql.service';
import { UtilityService } from '@app/shared/services/utility/utility.service';

// declare and export the project pending changes & keys reactive variables
export const projectPendingChanges: ReactiveVar<Partial<Project>> =
  makeVar<Partial<Project>>({});
export const projectPendingChangesKeys: ReactiveVar<string[]> =
  makeVar<string[]>([]);

// declare and export the survey questions pending changes reactive variable
export const surveyQuestionsPendingChanges: ReactiveVar<SurveyAnswerInput[]> =
  makeVar<SurveyAnswerInput[]>([]);


@Injectable({
  providedIn: 'root'
})
export class ProjectChangesService {

  constructor(
    private apollo: Apollo,
    private util: UtilityService
  ) { }

  // a subject broadcasting when to clear any existing survey question pending changes
  private _clearSurveyQuestionsPendingChanges: Subject<boolean> = new Subject();
  public readonly clearSurveyQuestionsPendingChanges: Observable<boolean> = this._clearSurveyQuestionsPendingChanges.asObservable();

  // clear all pending changes
  public clearPendingChanges(): void {
    projectPendingChanges({});
    projectPendingChangesKeys([]);
    surveyQuestionsPendingChanges([]);
    this._clearSurveyQuestionsPendingChanges.next(true);
  }

  public pendingChangesCount(): number {
    return projectPendingChangesKeys().length + surveyQuestionsPendingChanges().length;
  }

  // return whether the specified key has an
  // existing pending change
  public hasPendingChange(key: string): boolean {
    return projectPendingChangesKeys().includes(key);
  }

  // remove any existing pending change for the
  // specified key
  public removePendingChange(key: string): void {
    // retrieve the existing pending changes & keys
    // (clone the changes object)
    let changes: Partial<Project> = clone(projectPendingChanges());
    let keys: string[] = projectPendingChangesKeys();

    // remove the pending change
    deleteProperty(changes, key);
    // remove the pending change key
    keys = keys.filter(k => k !== key);

    // update the pending changes
    projectPendingChanges(changes);
    // update the pending changes keys
    // (ensuring there are no duplicate keys)
    projectPendingChangesKeys([...new Set(keys)]);
  }

  // attempt to retrieve an existing pending change
  // - returns the pending change if one exists, otherwise
  //   returns the original value
  public getPendingChange(data: Partial<Project>, key: string): unknown {
    return (
      hasProperty(data, `pendingChanges.${key}`) ?
        getProperty(data, `pendingChanges.${key}`) :
        getProperty(data, key)
    );
  }

  // get the value of an existing pending change
  public getPendingChangeValue(key: string): unknown {
    return getProperty(projectPendingChanges(), key);
  }

  // store a new pending change
  public storePendingChange(
    key: string,
    value: unknown,
    projectId: string,
    fragment: DocumentNode
  ): void {
    // retrive the existing pending changes & keys
    // (clone the changes object)
    let changes: Partial<Project> = clone(projectPendingChanges());
    let keys: string[] = projectPendingChangesKeys();

    // retrieve any relevant existing values from the cache using
    // the specified fragment
    let existingValues: any = this.apollo.getClient().readFragment({
      id: `Project:${projectId}`,
      fragment: fragment
    });

    if (existingValues) {
      // check if the new value is the same as the existing value
      // (i.e. it has been changed back to it's original value)
      // if so, don't proceed with storing the pending change and
      // remove any existing pending change values from pendingChanges
      // and pendingChangesKeys
      if (
        // if the value is not an object or is null, use direct comparison
        (
          (typeof (value) !== 'object' || value === null) &&
          value === getProperty(existingValues, key)
        ) ||
        // if the value is an object but not null, perform a shallowEquality comparison
        (
          ((typeof (value) === 'object' && !Array.isArray(value)) && value !== null) &&
          this.util.shallowEquality(value, getProperty(existingValues, key))
        ) ||
        // if the value is an array, use direct comparison
        (
          Array.isArray(value) &&
          value === getProperty(existingValues, key)
        )
      ) {
        // remove the pending change
        deleteProperty(changes, key);
        // remove the key from the pending changes keys
        keys = keys.filter(k => k !== key);
      }
      // else, store the pending change
      else {
        // set the new pending change
        setProperty(changes, key, value);
        // add the key to the pending changes keys
        keys.push(key);
      }

      // update the pending changes
      projectPendingChanges(changes);
      // update the pending changes keys
      // (ensuring there are no duplicate keys)
      projectPendingChangesKeys([...new Set(keys)]);
    }
  }

  // store a new pending change (ignoring existing values)
  public storePendingChangeIgnoreExisting(
    key: string,
    value: unknown
  ): void {
    // retrive the existing pending changes & keys
    // (clone the changes object)
    let changes: Partial<Project> = clone(projectPendingChanges());
    let keys: string[] = projectPendingChangesKeys();

    // set the new pending change
    setProperty(changes, key, value);
    // add the key to the pending changes keys
    keys.push(key);

    // update the pending changes
    projectPendingChanges(changes);
    // update the pending changes keys
    // (ensuring there are no duplicate keys)
    projectPendingChangesKeys([...new Set(keys)]);
  }

  // *** SURVEY QUESTIONS PENDING CHANGES ***
  // store a pending survey question change
  public storePendingSurveyQuestionChange(
    value: SurveyAnswerInput,
    existingAnswers: SurveyAnswer[],
    questionIsSingleInput: boolean
  ): void {
    // retrieve the existing pending changes
    let changes: SurveyAnswerInput[] = clone(surveyQuestionsPendingChanges());

    // remove any existing pending answer change for the question -
    // this is required as multiple values can make up a single
    // pending change (i.e. answer & unit)
    changes = changes.filter(change =>
      // if the question is single input, filter using the question id,
      // otherwise for multiple input filter using the question option id
      questionIsSingleInput ?
        change.surveyQuestionId !== value.surveyQuestionId :
        change.surveyQuestionOptionId !== value.surveyQuestionOptionId
    );

    // if the new value is different from the existing answer, store
    // the new pending change
    if (!existingAnswers.some(answer =>
      answer.surveyQuestionId === value.surveyQuestionId &&
      answer.surveyQuestionOptionId === value.surveyQuestionOptionId &&
      answer.answer === value.answer &&
      answer.unit === value.unit
    )) {
      // set the new pending change
      changes = [...changes, value];
    }

    // update the pending changes
    surveyQuestionsPendingChanges(changes);
  }

  // remove any existing pending change for the
  // specified question
  public removeSurveyQuestionPendingChange(questionId: string): void {
    // retrieve the existing pending changes (clone the object)
    let changes: SurveyAnswerInput[] = clone(surveyQuestionsPendingChanges());
    // remove the pending change
    changes = changes.filter(change => change.surveyQuestionId !== questionId);
    // update the pending changes
    surveyQuestionsPendingChanges(changes);
  }

  // remove any existing pending change for the
  // specified question option
  public removeSurveyQuestionOptionPendingChange(questionOptionId: string): void {
    // retrieve the existing pending changes (clone the object)
    let changes: SurveyAnswerInput[] = clone(surveyQuestionsPendingChanges());
    // remove the pending change
    changes = changes.filter(change => change.surveyQuestionOptionId !== questionOptionId);
    // update the pending changes
    surveyQuestionsPendingChanges(changes);
  }

  // return whether the specified survey question
  // has an existing pending change
  public surveyQuestionHasPendingChange(questionId: string): boolean {
    return surveyQuestionsPendingChanges().some(question =>
      question.surveyQuestionId === questionId
    );
  }

  // return whether the specific survey question option
  // has an existing pending change
  public surveyQuestionOptionHasPendingChange(questionOptionId: string): boolean {
    return surveyQuestionsPendingChanges().some(question =>
      question.surveyQuestionOptionId === questionOptionId
    );
  }

  // get any existing survey question pending change
  // (searching by question id)
  public getSurveyQuestionPendingChange(
    questionId: string
  ): SurveyAnswerInput | undefined {
    return surveyQuestionsPendingChanges().find(question =>
      question.surveyQuestionId === questionId
    );
  }

  // get any existing survey question pending change
  // (searching by question option id)
  public getSurveyQuestionOptionPendingChange(
    questionOptionId: string
  ): SurveyAnswerInput | undefined {
    return surveyQuestionsPendingChanges().find(question =>
      question.surveyQuestionOptionId === questionOptionId
    );
  }

}
