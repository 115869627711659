import { Injectable } from '@angular/core';

import { CustomRate } from '@app/shared/classes/custom-rate/custom-rate';
import { CustomRateSchedule } from '@app/shared/classes/custom-rate-schedule/custom-rate-schedule';
import { CombinedInvestmentOpportunityResult, Format, UserTimeOfUseRate } from '@app/core/services/graphql/graphql.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  // compare two objects for shallow equality
  public shallowEquality(object1: any, object2: any): boolean {
    let keys1: string[] = Object.keys(object1);
    let keys2: string[] = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }

    return true;
  }

  // merge array elements
  // (i.e. [[1, 3, 5], [2, 4, 6]] becomes [[1, 2], [3, 4], [5, 6]])
  public mergeArrayElements(arrays: any[][]): any[][] {
    return Array.from(arrays[0]).map((element, index) => [...arrays.map(array => array[index])]);
  }

  // return a new array with the average value of each position the provided arrays
  public averageArrayElements(arrays: any[][]): number[] {
    return Array.from(arrays[0]).map((element, index) =>
      arrays.reduce((value, currentArray) => value + currentArray[index], 0) / arrays.length
    );
  }

  // format the provided min and max values into a range
  public formatValueRange(val1: string | null, val2: string | null, showEstimateSign: boolean): string {
    // if both of the values are null
    if (!val1 && !val2) {
      return '—';
    }

    // if either of the values is null, return only the non-null value
    if (!val1) {
      return val2!;
    }
    if (!val2) {
      return val1!;
    }

    // if the two values match
    if (val1 === val2) {
      // return a single value (with or without the estimate sign)
      return showEstimateSign ? `≈ ${val1}` : val1;
    }
    // else as the two values do not match, return a normal range
    else {
      return `${val1} – ${val2}`;
    }
  }

  // returns whether the specified element is scrollable
  public elementIsScrollable(element: HTMLElement): boolean {
    // check whether the element has scrollable content by comparing
    // it's scroll and client height/width
    return (element.scrollHeight > element.clientHeight) ||
      (element.scrollWidth > element.clientWidth);
  }

  // parse the provided renewable name into a format suitable for navigation
  // (e.g. Pv Roof & Battery Storage becomes pv-roof-battery-storage)
  public parseRenewableName(name: string): string {
    return name.split(' ').filter(s => s !== '&').join('-').toLowerCase();
  }

  // parse the provided technology key to remove any "optimised" prefix
  public parseTechnologyKey(key: string) {
    const k = key.replace('optimised', '');
    return k[0].toLowerCase() + k.substring(1);
  }

  // implementation of the ruby each_cons function, which slices
  // an array into consecutive groups of a given size
  public eachCons(array: number[], size: number): number[][] {
    return Array.from(
      { length: array.length - size + 1 },
      (_, i) => array.slice(i, i + size)
    );
  }

  // return the color code for the specified period index
  public periodColor(period: number, reversed = false): string {
    const colors = [
      '#00a7b5',
      '#2e276c',
      '#258ab2',
      '#4f2dc1',
      '#0063d7',
      '#9817ad',
      '#c54c58',
      '#df5a30',
      '#642a00',
      '#525f1c',
      '#906c10',
      '#1d9b7d'
    ];
    return (
      reversed ?
      colors[12 - period] :
      colors[period - 1]
    ) || '#888888';
  }

  // transform the provided sam format data into a custom rate
  public transformSamFormatToCustomRate(samFormat: Format | UserTimeOfUseRate): CustomRate {
    // // ensure that tiers from the same period use the same color
    // let color: string = '#ffffff';
    // const createColor = (period: number[]) => {
    //   if (period[1] === 1) {
    //     color = randomColor({luminosity: 'light'});
    //   }
    //   return color;
    // };

    // return the new custom rate
    return new CustomRate({
      // add energy charges if they exist (i.e. "urEcTouMat" is not null)
      ...(samFormat.urEcTouMat && {
        energyCharge: {
          unit: 'kWh',
          periods: [
            ...samFormat.urEcTouMat.map(period => ({
              index: period[0],
              tier: period[1],
              maxUnitsPerMonth: period[2] === 1e+38 ? 'unlimited' as const : period[2],
              energyChargePerUnit: period[4],
              color: this.periodColor(period[0])
            }))
          ],
          weekdaySchedule: new CustomRateSchedule({
            jan: samFormat.urEcSchedWeekday![0],
            feb: samFormat.urEcSchedWeekday![1],
            mar: samFormat.urEcSchedWeekday![2],
            apr: samFormat.urEcSchedWeekday![3],
            may: samFormat.urEcSchedWeekday![4],
            jun: samFormat.urEcSchedWeekday![5],
            jul: samFormat.urEcSchedWeekday![6],
            aug: samFormat.urEcSchedWeekday![7],
            sep: samFormat.urEcSchedWeekday![8],
            oct: samFormat.urEcSchedWeekday![9],
            nov: samFormat.urEcSchedWeekday![10],
            dec: samFormat.urEcSchedWeekday![11]
          }),
          weekendSchedule: new CustomRateSchedule({
            jan: samFormat.urEcSchedWeekend![0],
            feb: samFormat.urEcSchedWeekend![1],
            mar: samFormat.urEcSchedWeekend![2],
            apr: samFormat.urEcSchedWeekend![3],
            may: samFormat.urEcSchedWeekend![4],
            jun: samFormat.urEcSchedWeekend![5],
            jul: samFormat.urEcSchedWeekend![6],
            aug: samFormat.urEcSchedWeekend![7],
            sep: samFormat.urEcSchedWeekend![8],
            oct: samFormat.urEcSchedWeekend![9],
            nov: samFormat.urEcSchedWeekend![10],
            dec: samFormat.urEcSchedWeekend![11]
          })
        }
      }),
      // add demand charges if they exist (i.e. "urDcTouMat" is not null)
      ...(samFormat.urDcTouMat && {
        demandCharge: {
          unit: 'kW',
          periods: [
            ...samFormat.urDcTouMat.map(period => ({
              index: period[0],
              tier: period[1],
              maxDemand: period[2] === 1e+38 ? 'unlimited' as const : period[2],
              demandCharge: period[3],
              color: this.periodColor(period[0], true)
            }))
          ],
          weekdaySchedule: new CustomRateSchedule({
            jan: samFormat.urDcSchedWeekday![0],
            feb: samFormat.urDcSchedWeekday![1],
            mar: samFormat.urDcSchedWeekday![2],
            apr: samFormat.urDcSchedWeekday![3],
            may: samFormat.urDcSchedWeekday![4],
            jun: samFormat.urDcSchedWeekday![5],
            jul: samFormat.urDcSchedWeekday![6],
            aug: samFormat.urDcSchedWeekday![7],
            sep: samFormat.urDcSchedWeekday![8],
            oct: samFormat.urDcSchedWeekday![9],
            nov: samFormat.urDcSchedWeekday![10],
            dec: samFormat.urDcSchedWeekday![11]
          }),
          weekendSchedule: new CustomRateSchedule({
            jan: samFormat.urDcSchedWeekend![0],
            feb: samFormat.urDcSchedWeekend![1],
            mar: samFormat.urDcSchedWeekend![2],
            apr: samFormat.urDcSchedWeekend![3],
            may: samFormat.urDcSchedWeekend![4],
            jun: samFormat.urDcSchedWeekend![5],
            jul: samFormat.urDcSchedWeekend![6],
            aug: samFormat.urDcSchedWeekend![7],
            sep: samFormat.urDcSchedWeekend![8],
            oct: samFormat.urDcSchedWeekend![9],
            nov: samFormat.urDcSchedWeekend![10],
            dec: samFormat.urDcSchedWeekend![11]
          })
        }
      })
    });
  }

  // transform the provided custom rate into the sam format
  public transformCustomRateToSamFormat(rate: CustomRate): Format {
    // find whether the custom rate has any demand charge periods with
    // a demand charge greater than 0
    const hasDemandCharges = rate.demandCharge.periods.some(period => period.demandCharge > 0);
    // transform and return the sam format
    return {
      // energy charges
      urEcTouMat: rate.energyCharge.periods.map(period => [
        period.index,
        period.tier,
        period.maxUnitsPerMonth === 'unlimited' ? 1e+38 : period.maxUnitsPerMonth,
        0,
        period.energyChargePerUnit,
        0
      ]),
      urEcSchedWeekday: [
        rate.energyCharge.weekdaySchedule.jan,
        rate.energyCharge.weekdaySchedule.feb,
        rate.energyCharge.weekdaySchedule.mar,
        rate.energyCharge.weekdaySchedule.apr,
        rate.energyCharge.weekdaySchedule.may,
        rate.energyCharge.weekdaySchedule.jun,
        rate.energyCharge.weekdaySchedule.jul,
        rate.energyCharge.weekdaySchedule.aug,
        rate.energyCharge.weekdaySchedule.sep,
        rate.energyCharge.weekdaySchedule.oct,
        rate.energyCharge.weekdaySchedule.nov,
        rate.energyCharge.weekdaySchedule.dec
      ] as number[][],
      urEcSchedWeekend: [
        rate.energyCharge.weekendSchedule.jan,
        rate.energyCharge.weekendSchedule.feb,
        rate.energyCharge.weekendSchedule.mar,
        rate.energyCharge.weekendSchedule.apr,
        rate.energyCharge.weekendSchedule.may,
        rate.energyCharge.weekendSchedule.jun,
        rate.energyCharge.weekendSchedule.jul,
        rate.energyCharge.weekendSchedule.aug,
        rate.energyCharge.weekendSchedule.sep,
        rate.energyCharge.weekendSchedule.oct,
        rate.energyCharge.weekendSchedule.nov,
        rate.energyCharge.weekendSchedule.dec
      ] as number[][],
      // demand charges
      urDcEnable: hasDemandCharges,
      // only add "urDcTouMat" if there are demand charges
      ...(
        hasDemandCharges &&
        {
          urDcTouMat: rate.demandCharge.periods
            .filter(period => period.demandCharge > 0)
            .map(period => [
              period.index,
              period.tier,
              period.maxDemand === 'unlimited' ? 1e+38 : period.maxDemand,
              period.demandCharge
            ])
        }
      ),
      // only add "urDcSchedWeekday" if there are demand charges
      ...(
        hasDemandCharges &&
        {
          urDcSchedWeekday: [
            rate.demandCharge.weekdaySchedule.jan,
            rate.demandCharge.weekdaySchedule.feb,
            rate.demandCharge.weekdaySchedule.mar,
            rate.demandCharge.weekdaySchedule.apr,
            rate.demandCharge.weekdaySchedule.may,
            rate.demandCharge.weekdaySchedule.jun,
            rate.demandCharge.weekdaySchedule.jul,
            rate.demandCharge.weekdaySchedule.aug,
            rate.demandCharge.weekdaySchedule.sep,
            rate.demandCharge.weekdaySchedule.oct,
            rate.demandCharge.weekdaySchedule.nov,
            rate.demandCharge.weekdaySchedule.dec
          ] as number[][]
        }
      ),
      // only add "urDcSchedWeekend" if there are demand charges
      ...(
        hasDemandCharges &&
        {
          urDcSchedWeekend: [
            rate.demandCharge.weekendSchedule.jan,
            rate.demandCharge.weekendSchedule.feb,
            rate.demandCharge.weekendSchedule.mar,
            rate.demandCharge.weekendSchedule.apr,
            rate.demandCharge.weekendSchedule.may,
            rate.demandCharge.weekendSchedule.jun,
            rate.demandCharge.weekendSchedule.jul,
            rate.demandCharge.weekendSchedule.aug,
            rate.demandCharge.weekendSchedule.sep,
            rate.demandCharge.weekendSchedule.oct,
            rate.demandCharge.weekendSchedule.nov,
            rate.demandCharge.weekendSchedule.dec
          ] as number[][]
        }
      )
    };
  }

  // m2 to ft2 conversion
  public m2ToFt2(value: number, decimalPlaces: number = 0): number {
    return Number((value * 10.764).toFixed(decimalPlaces));
  }

  // ft2 to m2 conversion
  public ft2ToM2(value: number, decimalPlaces: number = 0): number {
    return Number((value / 10.764).toFixed(decimalPlaces));
  }

  public getCombinedEcmTooltipText(combinedEcm: CombinedInvestmentOpportunityResult): string {
    return combinedEcm.ecmData?.map(data => data.name).join('\n') || '';
  }

  public getCombinedEcmTypesText(combinedEcm: CombinedInvestmentOpportunityResult): string {
    // Make a list of unique type names using Set.
    return [
      ...new Set(combinedEcm.ecmData?.map(data => data.demandSideResponseType))
    ].join(', ') || '';
  }
}