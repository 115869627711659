import { EnergyCharge } from '@app/shared/interfaces/energy-charge';
import { DemandCharge } from '@app/shared/interfaces/demand-charge';
import { CustomRateSchedule } from '@app/shared/classes/custom-rate-schedule/custom-rate-schedule';

export class CustomRate {
  public energyCharge: EnergyCharge = {
    unit: 'kWh',
    periods: [],
    weekdaySchedule: new CustomRateSchedule(),
    weekendSchedule: new CustomRateSchedule(),
  };
  public demandCharge: DemandCharge = {
    unit: 'kW',
    periods: [],
    weekdaySchedule: new CustomRateSchedule(),
    weekendSchedule: new CustomRateSchedule()
  };

  constructor(rate = {} as Partial<CustomRate>) {
    Object.assign(this, rate);
  }
}
