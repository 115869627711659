import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { SessionService } from '@app/core/services/session/session.service';

// has-session guard - checks whether a session exists
//  - if a session exists, return true and pass the guard
//  - if a session does not exist, navigate to the signin page

@Injectable({
  providedIn: 'root'
})
export class HasSessionGuard implements CanActivate, CanLoad {
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.sessionService.hasSession() ? true : this.router.parseUrl('/signin');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree {
    return this.sessionService.hasSession() ? true : this.router.parseUrl('/signin');
  }
}
