import { Injectable } from '@angular/core';

import { Session } from '@app/core/interfaces/session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  // get session - return the current session (if one exists)
  public getSession(): Session | null {
    // if no local session exists, return null
    if (!this.hasSession()) return null;

    return {
      token: localStorage.getItem('token')!,
      tokenExpirationTime: new Date(localStorage.getItem('tokenExpirationTime')!)
    };
  }

  // has session - return whether the user has a local session
  public hasSession(): boolean {
    // ensure that:
    // - all of the expected session values are present in storage
    // - the session has not expired (i.e. the expiry time is still
    //   in the future)
    return [
      'token',
      'tokenExpirationTime'
    ].every(key => localStorage.getItem(key) !== null) &&
    new Date(localStorage.getItem('tokenExpirationTime')!).valueOf() > Date.now();
  }

  // create session - create a new session
  public createSession(token: string, tokenExpirationTime: Date): void {
    localStorage.setItem('token', token);
    localStorage.setItem('tokenExpirationTime', new Date(tokenExpirationTime).toISOString());
  }

  // destroy session - destroy an existing session
  public destroySession(): void {
    // remove any session values from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpirationTime');
    // remove any persisted table settings
    // (i.e. any key which starts with "tableSettings::")
    [...Array(localStorage.length).keys()].map(i => {
      const key = localStorage.key(i);
      if (key && key.startsWith('tableSettings::')) {
        localStorage.removeItem(key);
      }
    });
  }
}
