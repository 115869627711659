import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot,
  RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { SessionService } from '@app/core/services/session/session.service';

// no-session guard - checks whether no session exists
//   - if no session exists, return true and pass the guard
//   - if a session exists, navigate to the projects page

@Injectable({
  providedIn: 'root'
})
export class NoSessionGuard implements CanActivate, CanLoad {
  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    return this.sessionService.hasSession() ? this.router.parseUrl('/projects') : true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | UrlTree {
    return this.sessionService.hasSession() ? this.router.parseUrl('/projects') : true;
  }
}
